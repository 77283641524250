import React, { useState } from "react";
import styled from "styled-components";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";

import Cars from "@common/Cars";
import Footer from "@sections/Footer";

import ReservationBox from "@common/ReservationBox";
import { Modal } from "@material-ui/core";
import { Container } from "@components/global";

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

const StyledReservationBox = styled(ReservationBox)`
  max-width: 450px;
  height: auto;
`;

const CarsPage = () => {
  const [reservationPopup, setReservationPopup] = useState(null);

  const closeReservationPopup = () => setReservationPopup(null);
  return (
    <Layout
      seo={{
        url: "https://www.najtansza-wypozyczalnia-samochodow.pl/samochody",
      }}
    >
      {reservationPopup ? (
        <StyledModal open={true} onClose={closeReservationPopup}>
          <StyledReservationBox {...reservationPopup} />
        </StyledModal>
      ) : null}
      <TopHeader />
      <Container>
        <Cars
          onChoose={(car, segment) => {
            setReservationPopup({
              car,
              segment,
            });
          }}
        />
      </Container>
      <Footer />
    </Layout>
  );
};

export default CarsPage;
